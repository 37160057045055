import { 
  NetherlandsAntillesIcon,
  CoteDivoireIcon,
  RepublicOfAbkhaziaIcon,
  ElandIslandsIcon,
  SouthOssetiaIcon,
  TransnistriaIcon,
  FederalRepublicOfAmbazoniaIcon,
  NagornoKarabakhIcon,
  EuropeanUnionIcon,

} from '@/pages/entity/models/contries-base-64-icons'

export const localCountriesList = [
  { name: "Federal Republic of Ambazonia", icon: FederalRepublicOfAmbazoniaIcon},
  { name: "Nagorno-Karabakh", icon: NagornoKarabakhIcon},
  { name: "European Union", icon: EuropeanUnionIcon},
  { name: "Cote d'Ivoire", icon: CoteDivoireIcon},
  { name: 'Republic of Abkhazia', icon: RepublicOfAbkhaziaIcon},
  { name: 'Eland Islands', icon: ElandIslandsIcon},
  { name: 'South Ossetia', icon: SouthOssetiaIcon},
  { name: 'Transnistria', icon: TransnistriaIcon},
  { name: 'Netherlands Antilles', icon: NetherlandsAntillesIcon},
  { name: 'French Southern and Antarctic Lands', icon: ''},
  { name: 'Paracel Islands', icon: ''},
  { name: 'Spratly Islands', icon: ''},
  { name: 'United States Virgin Islands', icon: ''},
  { name: 'Palestinian Territory', icon: ''},
  { name: 'Ontario', icon: ''},
  { name: 'Laos', icon: ''},
  { name: 'Crimea', icon: ''},
  { name: 'Donetsk', icon: ''},
  { name: 'Luhansk', icon: ''},
  { name: 'Kosovo', icon: ''},
  { name: 'Pridnestrovie', icon: ''},
]

export const countriesForRestrictedJurisdictions = [
  { name: 'FATF List', icon: '', link: 'https://www.fatf-gafi.org/en/countries/black-and-grey-lists.html'},
]

export const allCountriesListV2 = [
    {
        "icon": "https://upload.wikimedia.org/wikipedia/commons/5/5c/Flag_of_the_Taliban.svg",
        "name": "Afghanistan",
        "code": "AF"
    },
    {
        "icon": "https://flagcdn.com/ax.svg",
        "name": "Åland Islands",
        "code": "AX"
    },
    {
        "icon": "https://flagcdn.com/al.svg",
        "name": "Albania",
        "code": "AL"
    },
    {
        "icon": "https://flagcdn.com/dz.svg",
        "name": "Algeria",
        "code": "DZ"
    },
    {
        "icon": "https://flagcdn.com/as.svg",
        "name": "American Samoa",
        "code": "AS"
    },
    {
        "icon": "https://flagcdn.com/ad.svg",
        "name": "Andorra",
        "code": "AD"
    },
    {
        "icon": "https://flagcdn.com/ao.svg",
        "name": "Angola",
        "code": "AO"
    },
    {
        "icon": "https://flagcdn.com/ai.svg",
        "name": "Anguilla",
        "code": "AI"
    },
    {
        "icon": "https://flagcdn.com/aq.svg",
        "name": "Antarctica",
        "code": "AQ"
    },
    {
        "icon": "https://flagcdn.com/ag.svg",
        "name": "Antigua and Barbuda",
        "code": "AG"
    },
    {
        "icon": "https://flagcdn.com/ar.svg",
        "name": "Argentina",
        "code": "AR"
    },
    {
        "icon": "https://flagcdn.com/am.svg",
        "name": "Armenia",
        "code": "AM"
    },
    {
        "icon": "https://flagcdn.com/aw.svg",
        "name": "Aruba",
        "code": "AW"
    },
    {
        "icon": "https://flagcdn.com/au.svg",
        "name": "Australia",
        "code": "AU"
    },
    {
        "icon": "https://flagcdn.com/at.svg",
        "name": "Austria",
        "code": "AT"
    },
    {
        "icon": "https://flagcdn.com/az.svg",
        "name": "Azerbaijan",
        "code": "AZ"
    },
    {
        "icon": "https://flagcdn.com/bs.svg",
        "name": "Bahamas",
        "code": "BS"
    },
    {
        "icon": "https://flagcdn.com/bh.svg",
        "name": "Bahrain",
        "code": "BH"
    },
    {
        "icon": "https://flagcdn.com/bd.svg",
        "name": "Bangladesh",
        "code": "BD"
    },
    {
        "icon": "https://flagcdn.com/bb.svg",
        "name": "Barbados",
        "code": "BB"
    },
    {
        "icon": "https://flagcdn.com/by.svg",
        "name": "Belarus",
        "code": "BY"
    },
    {
        "icon": "https://flagcdn.com/be.svg",
        "name": "Belgium",
        "code": "BE"
    },
    {
        "icon": "https://flagcdn.com/bz.svg",
        "name": "Belize",
        "code": "BZ"
    },
    {
        "icon": "https://flagcdn.com/bj.svg",
        "name": "Benin",
        "code": "BJ"
    },
    {
        "icon": "https://flagcdn.com/bm.svg",
        "name": "Bermuda",
        "code": "BM"
    },
    {
        "icon": "https://flagcdn.com/bt.svg",
        "name": "Bhutan",
        "code": "BT"
    },
    {
        "icon": "https://flagcdn.com/bo.svg",
        "name": "Bolivia (Plurinational State of)",
        "code": "BO"
    },
    {
        "icon": "https://flagcdn.com/bq.svg",
        "name": "Bonaire, Sint Eustatius and Saba",
        "code": "BQ"
    },
    {
        "icon": "https://flagcdn.com/ba.svg",
        "name": "Bosnia and Herzegovina",
        "code": "BA"
    },
    {
        "icon": "https://flagcdn.com/bw.svg",
        "name": "Botswana",
        "code": "BW"
    },
    {
        "icon": "https://flagcdn.com/bv.svg",
        "name": "Bouvet Island",
        "code": "BV"
    },
    {
        "icon": "https://flagcdn.com/br.svg",
        "name": "Brazil",
        "code": "BR"
    },
    {
        "icon": "https://flagcdn.com/io.svg",
        "name": "British Indian Ocean Territory",
        "code": "IO"
    },
    {
        "icon": "https://flagcdn.com/um.svg",
        "name": "United States Minor Outlying Islands",
        "code": "UM"
    },
    {
        "icon": "https://flagcdn.com/vg.svg",
        "name": "Virgin Islands (British)",
        "code": "VG"
    },
    {
        "icon": "https://flagcdn.com/vi.svg",
        "name": "Virgin Islands (U.S.)",
        "code": "VI"
    },
    {
        "icon": "https://flagcdn.com/bn.svg",
        "name": "Brunei Darussalam",
        "code": "BN"
    },
    {
        "icon": "https://flagcdn.com/bg.svg",
        "name": "Bulgaria",
        "code": "BG"
    },
    {
        "icon": "https://flagcdn.com/bf.svg",
        "name": "Burkina Faso",
        "code": "BF"
    },
    {
        "icon": "https://flagcdn.com/bi.svg",
        "name": "Burundi",
        "code": "BI"
    },
    {
        "icon": "https://flagcdn.com/kh.svg",
        "name": "Cambodia",
        "code": "KH"
    },
    {
        "icon": "https://flagcdn.com/cm.svg",
        "name": "Cameroon",
        "code": "CM"
    },
    {
        "icon": "https://flagcdn.com/ca.svg",
        "name": "Canada",
        "code": "CA"
    },
    {
        "icon": "https://flagcdn.com/cv.svg",
        "name": "Cabo Verde",
        "code": "CV"
    },
    {
        "icon": "https://flagcdn.com/ky.svg",
        "name": "Cayman Islands",
        "code": "KY"
    },
    {
        "icon": "https://flagcdn.com/cf.svg",
        "name": "Central African Republic",
        "code": "CF"
    },
    {
        "icon": "https://flagcdn.com/td.svg",
        "name": "Chad",
        "code": "TD"
    },
    {
        "icon": "https://flagcdn.com/cl.svg",
        "name": "Chile",
        "code": "CL"
    },
    {
        "icon": "https://flagcdn.com/cn.svg",
        "name": "China",
        "code": "CN"
    },
    {
        "icon": "https://flagcdn.com/cx.svg",
        "name": "Christmas Island",
        "code": "CX"
    },
    {
        "icon": "https://flagcdn.com/cc.svg",
        "name": "Cocos (Keeling) Islands",
        "code": "CC"
    },
    {
        "icon": "https://flagcdn.com/co.svg",
        "name": "Colombia",
        "code": "CO"
    },
    {
        "icon": "https://flagcdn.com/km.svg",
        "name": "Comoros",
        "code": "KM"
    },
    {
        "icon": "https://flagcdn.com/cg.svg",
        "name": "Congo",
        "code": "CG"
    },
    {
        "icon": "https://flagcdn.com/cd.svg",
        "name": "Congo (Democratic Republic of the)",
        "code": "CD"
    },
    {
        "icon": "https://flagcdn.com/ck.svg",
        "name": "Cook Islands",
        "code": "CK"
    },
    {
        "icon": "https://flagcdn.com/cr.svg",
        "name": "Costa Rica",
        "code": "CR"
    },
    {
        "icon": "https://flagcdn.com/hr.svg",
        "name": "Croatia",
        "code": "HR"
    },
    {
        "icon": "https://flagcdn.com/cu.svg",
        "name": "Cuba",
        "code": "CU"
    },
    {
        "icon": "https://flagcdn.com/cw.svg",
        "name": "Curaçao",
        "code": "CW"
    },
    {
        "icon": "https://flagcdn.com/cy.svg",
        "name": "Cyprus",
        "code": "CY"
    },
    {
        "icon": "https://flagcdn.com/cz.svg",
        "name": "Czech Republic",
        "code": "CZ"
    },
    {
        "icon": "https://flagcdn.com/dk.svg",
        "name": "Denmark",
        "code": "DK"
    },
    {
        "icon": "https://flagcdn.com/dj.svg",
        "name": "Djibouti",
        "code": "DJ"
    },
    {
        "icon": "https://flagcdn.com/dm.svg",
        "name": "Dominica",
        "code": "DM"
    },
    {
        "icon": "https://flagcdn.com/do.svg",
        "name": "Dominican Republic",
        "code": "DO"
    },
    {
        "icon": "https://flagcdn.com/ec.svg",
        "name": "Ecuador",
        "code": "EC"
    },
    {
        "icon": "https://flagcdn.com/eg.svg",
        "name": "Egypt",
        "code": "EG"
    },
    {
        "icon": "https://flagcdn.com/sv.svg",
        "name": "El Salvador",
        "code": "SV"
    },
    {
        "icon": "https://flagcdn.com/gq.svg",
        "name": "Equatorial Guinea",
        "code": "GQ"
    },
    {
        "icon": "https://flagcdn.com/er.svg",
        "name": "Eritrea",
        "code": "ER"
    },
    {
        "icon": "https://flagcdn.com/ee.svg",
        "name": "Estonia",
        "code": "EE"
    },
    {
        "icon": "https://flagcdn.com/et.svg",
        "name": "Ethiopia",
        "code": "ET"
    },
    {
        "icon": "https://flagcdn.com/fk.svg",
        "name": "Falkland Islands (Malvinas)",
        "code": "FK"
    },
    {
        "icon": "https://flagcdn.com/fo.svg",
        "name": "Faroe Islands",
        "code": "FO"
    },
    {
        "icon": "https://flagcdn.com/fj.svg",
        "name": "Fiji",
        "code": "FJ"
    },
    {
        "icon": "https://flagcdn.com/fi.svg",
        "name": "Finland",
        "code": "FI"
    },
    {
        "icon": "https://flagcdn.com/fr.svg",
        "name": "France",
        "code": "FR"
    },
    {
        "icon": "https://flagcdn.com/gf.svg",
        "name": "French Guiana",
        "code": "GF"
    },
    {
        "icon": "https://flagcdn.com/pf.svg",
        "name": "French Polynesia",
        "code": "PF"
    },
    {
        "icon": "https://flagcdn.com/tf.svg",
        "name": "French Southern Territories",
        "code": "TF"
    },
    {
        "icon": "https://flagcdn.com/ga.svg",
        "name": "Gabon",
        "code": "GA"
    },
    {
        "icon": "https://flagcdn.com/gm.svg",
        "name": "Gambia",
        "code": "GM"
    },
    {
        "icon": "https://flagcdn.com/ge.svg",
        "name": "Georgia",
        "code": "GE"
    },
    {
        "icon": "https://flagcdn.com/de.svg",
        "name": "Germany",
        "code": "DE"
    },
    {
        "icon": "https://flagcdn.com/gh.svg",
        "name": "Ghana",
        "code": "GH"
    },
    {
        "icon": "https://flagcdn.com/gi.svg",
        "name": "Gibraltar",
        "code": "GI"
    },
    {
        "icon": "https://flagcdn.com/gr.svg",
        "name": "Greece",
        "code": "GR"
    },
    {
        "icon": "https://flagcdn.com/gl.svg",
        "name": "Greenland",
        "code": "GL"
    },
    {
        "icon": "https://flagcdn.com/gd.svg",
        "name": "Grenada",
        "code": "GD"
    },
    {
        "icon": "https://flagcdn.com/gp.svg",
        "name": "Guadeloupe",
        "code": "GP"
    },
    {
        "icon": "https://flagcdn.com/gu.svg",
        "name": "Guam",
        "code": "GU"
    },
    {
        "icon": "https://flagcdn.com/gt.svg",
        "name": "Guatemala",
        "code": "GT"
    },
    {
        "icon": "https://flagcdn.com/gg.svg",
        "name": "Guernsey",
        "code": "GG"
    },
    {
        "icon": "https://flagcdn.com/gn.svg",
        "name": "Guinea",
        "code": "GN"
    },
    {
        "icon": "https://flagcdn.com/gw.svg",
        "name": "Guinea-Bissau",
        "code": "GW"
    },
    {
        "icon": "https://flagcdn.com/gy.svg",
        "name": "Guyana",
        "code": "GY"
    },
    {
        "icon": "https://flagcdn.com/ht.svg",
        "name": "Haiti",
        "code": "HT"
    },
    {
        "icon": "https://flagcdn.com/hm.svg",
        "name": "Heard Island and McDonald Islands",
        "code": "HM"
    },
    {
        "icon": "https://flagcdn.com/va.svg",
        "name": "Vatican City",
        "code": "VA"
    },
    {
        "icon": "https://flagcdn.com/hn.svg",
        "name": "Honduras",
        "code": "HN"
    },
    {
        "icon": "https://flagcdn.com/hu.svg",
        "name": "Hungary",
        "code": "HU"
    },
    {
        "icon": "https://flagcdn.com/hk.svg",
        "name": "Hong Kong",
        "code": "HK"
    },
    {
        "icon": "https://flagcdn.com/is.svg",
        "name": "Iceland",
        "code": "IS"
    },
    {
        "icon": "https://flagcdn.com/in.svg",
        "name": "India",
        "code": "IN"
    },
    {
        "icon": "https://flagcdn.com/id.svg",
        "name": "Indonesia",
        "code": "ID"
    },
    {
        "icon": "https://flagcdn.com/ci.svg",
        "name": "Ivory Coast",
        "code": "CI"
    },
    {
        "icon": "https://flagcdn.com/ir.svg",
        "name": "Iran (Islamic Republic of)",
        "code": "IR"
    },
    {
        "icon": "https://flagcdn.com/iq.svg",
        "name": "Iraq",
        "code": "IQ"
    },
    {
        "icon": "https://flagcdn.com/ie.svg",
        "name": "Ireland",
        "code": "IE"
    },
    {
        "icon": "https://flagcdn.com/im.svg",
        "name": "Isle of Man",
        "code": "IM"
    },
    {
        "icon": "https://flagcdn.com/il.svg",
        "name": "Israel",
        "code": "IL"
    },
    {
        "icon": "https://flagcdn.com/it.svg",
        "name": "Italy",
        "code": "IT"
    },
    {
        "icon": "https://flagcdn.com/jm.svg",
        "name": "Jamaica",
        "code": "JM"
    },
    {
        "icon": "https://flagcdn.com/jp.svg",
        "name": "Japan",
        "code": "JP"
    },
    {
        "icon": "https://flagcdn.com/je.svg",
        "name": "Jersey",
        "code": "JE"
    },
    {
        "icon": "https://flagcdn.com/jo.svg",
        "name": "Jordan",
        "code": "JO"
    },
    {
        "icon": "https://flagcdn.com/kz.svg",
        "name": "Kazakhstan",
        "code": "KZ"
    },
    {
        "icon": "https://flagcdn.com/ke.svg",
        "name": "Kenya",
        "code": "KE"
    },
    {
        "icon": "https://flagcdn.com/ki.svg",
        "name": "Kiribati",
        "code": "KI"
    },
    {
        "icon": "https://flagcdn.com/kw.svg",
        "name": "Kuwait",
        "code": "KW"
    },
    {
        "icon": "https://flagcdn.com/kg.svg",
        "name": "Kyrgyzstan",
        "code": "KG"
    },
    {
        "icon": "https://flagcdn.com/la.svg",
        "name": "Lao People's Democratic Republic",
        "code": "LA"
    },
    {
        "icon": "https://flagcdn.com/lv.svg",
        "name": "Latvia",
        "code": "LV"
    },
    {
        "icon": "https://flagcdn.com/lb.svg",
        "name": "Lebanon",
        "code": "LB"
    },
    {
        "icon": "https://flagcdn.com/ls.svg",
        "name": "Lesotho",
        "code": "LS"
    },
    {
        "icon": "https://flagcdn.com/lr.svg",
        "name": "Liberia",
        "code": "LR"
    },
    {
        "icon": "https://flagcdn.com/ly.svg",
        "name": "Libya",
        "code": "LY"
    },
    {
        "icon": "https://flagcdn.com/li.svg",
        "name": "Liechtenstein",
        "code": "LI"
    },
    {
        "icon": "https://flagcdn.com/lt.svg",
        "name": "Lithuania",
        "code": "LT"
    },
    {
        "icon": "https://flagcdn.com/lu.svg",
        "name": "Luxembourg",
        "code": "LU"
    },
    {
        "icon": "https://flagcdn.com/mo.svg",
        "name": "Macao",
        "code": "MO"
    },
    {
        "icon": "https://flagcdn.com/mk.svg",
        "name": "North Macedonia",
        "code": "MK"
    },
    {
        "icon": "https://flagcdn.com/mg.svg",
        "name": "Madagascar",
        "code": "MG"
    },
    {
        "icon": "https://flagcdn.com/mw.svg",
        "name": "Malawi",
        "code": "MW"
    },
    {
        "icon": "https://flagcdn.com/my.svg",
        "name": "Malaysia",
        "code": "MY"
    },
    {
        "icon": "https://flagcdn.com/mv.svg",
        "name": "Maldives",
        "code": "MV"
    },
    {
        "icon": "https://flagcdn.com/ml.svg",
        "name": "Mali",
        "code": "ML"
    },
    {
        "icon": "https://flagcdn.com/mt.svg",
        "name": "Malta",
        "code": "MT"
    },
    {
        "icon": "https://flagcdn.com/mh.svg",
        "name": "Marshall Islands",
        "code": "MH"
    },
    {
        "icon": "https://flagcdn.com/mq.svg",
        "name": "Martinique",
        "code": "MQ"
    },
    {
        "icon": "https://flagcdn.com/mr.svg",
        "name": "Mauritania",
        "code": "MR"
    },
    {
        "icon": "https://flagcdn.com/mu.svg",
        "name": "Mauritius",
        "code": "MU"
    },
    {
        "icon": "https://flagcdn.com/yt.svg",
        "name": "Mayotte",
        "code": "YT"
    },
    {
        "icon": "https://flagcdn.com/mx.svg",
        "name": "Mexico",
        "code": "MX"
    },
    {
        "icon": "https://flagcdn.com/fm.svg",
        "name": "Micronesia (Federated States of)",
        "code": "FM"
    },
    {
        "icon": "https://flagcdn.com/md.svg",
        "name": "Moldova (Republic of)",
        "code": "MD"
    },
    {
        "icon": "https://flagcdn.com/mc.svg",
        "name": "Monaco",
        "code": "MC"
    },
    {
        "icon": "https://flagcdn.com/mn.svg",
        "name": "Mongolia",
        "code": "MN"
    },
    {
        "icon": "https://flagcdn.com/me.svg",
        "name": "Montenegro",
        "code": "ME"
    },
    {
        "icon": "https://flagcdn.com/ms.svg",
        "name": "Montserrat",
        "code": "MS"
    },
    {
        "icon": "https://flagcdn.com/ma.svg",
        "name": "Morocco",
        "code": "MA"
    },
    {
        "icon": "https://flagcdn.com/mz.svg",
        "name": "Mozambique",
        "code": "MZ"
    },
    {
        "icon": "https://flagcdn.com/mm.svg",
        "name": "Myanmar",
        "code": "MM"
    },
    {
        "icon": "https://flagcdn.com/na.svg",
        "name": "Namibia",
        "code": "NA"
    },
    {
        "icon": "https://flagcdn.com/nr.svg",
        "name": "Nauru",
        "code": "NR"
    },
    {
        "icon": "https://flagcdn.com/np.svg",
        "name": "Nepal",
        "code": "NP"
    },
    {
        "icon": "https://flagcdn.com/nl.svg",
        "name": "Netherlands",
        "code": "NL"
    },
    {
        "icon": "https://flagcdn.com/nc.svg",
        "name": "New Caledonia",
        "code": "NC"
    },
    {
        "icon": "https://flagcdn.com/nz.svg",
        "name": "New Zealand",
        "code": "NZ"
    },
    {
        "icon": "https://flagcdn.com/ni.svg",
        "name": "Nicaragua",
        "code": "NI"
    },
    {
        "icon": "https://flagcdn.com/ne.svg",
        "name": "Niger",
        "code": "NE"
    },
    {
        "icon": "https://flagcdn.com/ng.svg",
        "name": "Nigeria",
        "code": "NG"
    },
    {
        "icon": "https://flagcdn.com/nu.svg",
        "name": "Niue",
        "code": "NU"
    },
    {
        "icon": "https://flagcdn.com/nf.svg",
        "name": "Norfolk Island",
        "code": "NF"
    },
    {
        "icon": "https://flagcdn.com/kp.svg",
        "name": "Korea (Democratic People's Republic of)",
        "code": "KP"
    },
    {
        "icon": "https://flagcdn.com/mp.svg",
        "name": "Northern Mariana Islands",
        "code": "MP"
    },
    {
        "icon": "https://flagcdn.com/no.svg",
        "name": "Norway",
        "code": "NO"
    },
    {
        "icon": "https://flagcdn.com/om.svg",
        "name": "Oman",
        "code": "OM"
    },
    {
        "icon": "https://flagcdn.com/pk.svg",
        "name": "Pakistan",
        "code": "PK"
    },
    {
        "icon": "https://flagcdn.com/pw.svg",
        "name": "Palau",
        "code": "PW"
    },
    {
        "icon": "https://flagcdn.com/ps.svg",
        "name": "Palestine, State of",
        "code": "PS"
    },
    {
        "icon": "https://flagcdn.com/pa.svg",
        "name": "Panama",
        "code": "PA"
    },
    {
        "icon": "https://flagcdn.com/pg.svg",
        "name": "Papua New Guinea",
        "code": "PG"
    },
    {
        "icon": "https://flagcdn.com/py.svg",
        "name": "Paraguay",
        "code": "PY"
    },
    {
        "icon": "https://flagcdn.com/pe.svg",
        "name": "Peru",
        "code": "PE"
    },
    {
        "icon": "https://flagcdn.com/ph.svg",
        "name": "Philippines",
        "code": "PH"
    },
    {
        "icon": "https://flagcdn.com/pn.svg",
        "name": "Pitcairn",
        "code": "PN"
    },
    {
        "icon": "https://flagcdn.com/pl.svg",
        "name": "Poland",
        "code": "PL"
    },
    {
        "icon": "https://flagcdn.com/pt.svg",
        "name": "Portugal",
        "code": "PT"
    },
    {
        "icon": "https://flagcdn.com/pr.svg",
        "name": "Puerto Rico",
        "code": "PR"
    },
    {
        "icon": "https://flagcdn.com/qa.svg",
        "name": "Qatar",
        "code": "QA"
    },
    {
        "icon": "https://flagcdn.com/xk.svg",
        "name": "Republic of Kosovo",
        "code": "XK"
    },
    {
        "icon": "https://flagcdn.com/re.svg",
        "name": "Réunion",
        "code": "RE"
    },
    {
        "icon": "https://flagcdn.com/ro.svg",
        "name": "Romania",
        "code": "RO"
    },
    {
        "icon": "https://flagcdn.com/ru.svg",
        "name": "Russian Federation",
        "code": "RU"
    },
    {
        "icon": "https://flagcdn.com/rw.svg",
        "name": "Rwanda",
        "code": "RW"
    },
    {
        "icon": "https://flagcdn.com/bl.svg",
        "name": "Saint Barthélemy",
        "code": "BL"
    },
    {
        "icon": "https://flagcdn.com/sh.svg",
        "name": "Saint Helena, Ascension and Tristan da Cunha",
        "code": "SH"
    },
    {
        "icon": "https://flagcdn.com/kn.svg",
        "name": "Saint Kitts and Nevis",
        "code": "KN"
    },
    {
        "icon": "https://flagcdn.com/lc.svg",
        "name": "Saint Lucia",
        "code": "LC"
    },
    {
        "icon": "https://flagcdn.com/mf.svg",
        "name": "Saint Martin (French part)",
        "code": "MF"
    },
    {
        "icon": "https://flagcdn.com/pm.svg",
        "name": "Saint Pierre and Miquelon",
        "code": "PM"
    },
    {
        "icon": "https://flagcdn.com/vc.svg",
        "name": "Saint Vincent and the Grenadines",
        "code": "VC"
    },
    {
        "icon": "https://flagcdn.com/ws.svg",
        "name": "Samoa",
        "code": "WS"
    },
    {
        "icon": "https://flagcdn.com/sm.svg",
        "name": "San Marino",
        "code": "SM"
    },
    {
        "icon": "https://flagcdn.com/st.svg",
        "name": "Sao Tome and Principe",
        "code": "ST"
    },
    {
        "icon": "https://flagcdn.com/sa.svg",
        "name": "Saudi Arabia",
        "code": "SA"
    },
    {
        "icon": "https://flagcdn.com/sn.svg",
        "name": "Senegal",
        "code": "SN"
    },
    {
        "icon": "https://flagcdn.com/rs.svg",
        "name": "Serbia",
        "code": "RS"
    },
    {
        "icon": "https://flagcdn.com/sc.svg",
        "name": "Seychelles",
        "code": "SC"
    },
    {
        "icon": "https://flagcdn.com/sl.svg",
        "name": "Sierra Leone",
        "code": "SL"
    },
    {
        "icon": "https://flagcdn.com/sg.svg",
        "name": "Singapore",
        "code": "SG"
    },
    {
        "icon": "https://flagcdn.com/sx.svg",
        "name": "Sint Maarten (Dutch part)",
        "code": "SX"
    },
    {
        "icon": "https://flagcdn.com/sk.svg",
        "name": "Slovakia",
        "code": "SK"
    },
    {
        "icon": "https://flagcdn.com/si.svg",
        "name": "Slovenia",
        "code": "SI"
    },
    {
        "icon": "https://flagcdn.com/sb.svg",
        "name": "Solomon Islands",
        "code": "SB"
    },
    {
        "icon": "https://flagcdn.com/so.svg",
        "name": "Somalia",
        "code": "SO"
    },
    {
        "icon": "https://flagcdn.com/za.svg",
        "name": "South Africa",
        "code": "ZA"
    },
    {
        "icon": "https://flagcdn.com/gs.svg",
        "name": "South Georgia and the South Sandwich Islands",
        "code": "GS"
    },
    {
        "icon": "https://flagcdn.com/kr.svg",
        "name": "Korea (Republic of)",
        "code": "KR"
    },
    {
        "icon": "https://flagcdn.com/es.svg",
        "name": "Spain",
        "code": "ES"
    },
    {
        "icon": "https://flagcdn.com/lk.svg",
        "name": "Sri Lanka",
        "code": "LK"
    },
    {
        "icon": "https://flagcdn.com/sd.svg",
        "name": "Sudan",
        "code": "SD"
    },
    {
        "icon": "https://flagcdn.com/ss.svg",
        "name": "South Sudan",
        "code": "SS"
    },
    {
        "icon": "https://flagcdn.com/sr.svg",
        "name": "Suriname",
        "code": "SR"
    },
    {
        "icon": "https://flagcdn.com/sj.svg",
        "name": "Svalbard and Jan Mayen",
        "code": "SJ"
    },
    {
        "icon": "https://flagcdn.com/sz.svg",
        "name": "Swaziland",
        "code": "SZ"
    },
    {
        "icon": "https://flagcdn.com/se.svg",
        "name": "Sweden",
        "code": "SE"
    },
    {
        "icon": "https://flagcdn.com/ch.svg",
        "name": "Switzerland",
        "code": "CH"
    },
    {
        "icon": "https://flagcdn.com/sy.svg",
        "name": "Syrian Arab Republic",
        "code": "SY"
    },
    {
        "icon": "https://flagcdn.com/tw.svg",
        "name": "Taiwan",
        "code": "TW"
    },
    {
        "icon": "https://flagcdn.com/tj.svg",
        "name": "Tajikistan",
        "code": "TJ"
    },
    {
        "icon": "https://flagcdn.com/tz.svg",
        "name": "Tanzania, United Republic of",
        "code": "TZ"
    },
    {
        "icon": "https://flagcdn.com/th.svg",
        "name": "Thailand",
        "code": "TH"
    },
    {
        "icon": "https://flagcdn.com/tl.svg",
        "name": "Timor-Leste",
        "code": "TL"
    },
    {
        "icon": "https://flagcdn.com/tg.svg",
        "name": "Togo",
        "code": "TG"
    },
    {
        "icon": "https://flagcdn.com/tk.svg",
        "name": "Tokelau",
        "code": "TK"
    },
    {
        "icon": "https://flagcdn.com/to.svg",
        "name": "Tonga",
        "code": "TO"
    },
    {
        "icon": "https://flagcdn.com/tt.svg",
        "name": "Trinidad and Tobago",
        "code": "TT"
    },
    {
        "icon": "https://flagcdn.com/tn.svg",
        "name": "Tunisia",
        "code": "TN"
    },
    {
        "icon": "https://flagcdn.com/tr.svg",
        "name": "Turkey",
        "code": "TR"
    },
    {
        "icon": "https://flagcdn.com/tm.svg",
        "name": "Turkmenistan",
        "code": "TM"
    },
    {
        "icon": "https://flagcdn.com/tc.svg",
        "name": "Turks and Caicos Islands",
        "code": "TC"
    },
    {
        "icon": "https://flagcdn.com/tv.svg",
        "name": "Tuvalu",
        "code": "TV"
    },
    {
        "icon": "https://flagcdn.com/ug.svg",
        "name": "Uganda",
        "code": "UG"
    },
    {
        "icon": "https://flagcdn.com/ua.svg",
        "name": "Ukraine",
        "code": "UA"
    },
    {
        "icon": "https://flagcdn.com/ae.svg",
        "name": "United Arab Emirates",
        "code": "AE"
    },
    {
        "icon": "https://flagcdn.com/gb.svg",
        "name": "United Kingdom of Great Britain and Northern Ireland",
        "code": "GB"
    },
    {
        "icon": "https://flagcdn.com/us.svg",
        "name": "United States of America",
        "code": "US"
    },
    {
        "icon": "https://flagcdn.com/uy.svg",
        "name": "Uruguay",
        "code": "UY"
    },
    {
        "icon": "https://flagcdn.com/uz.svg",
        "name": "Uzbekistan",
        "code": "UZ"
    },
    {
        "icon": "https://flagcdn.com/vu.svg",
        "name": "Vanuatu",
        "code": "VU"
    },
    {
        "icon": "https://flagcdn.com/ve.svg",
        "name": "Venezuela (Bolivarian Republic of)",
        "code": "VE"
    },
    {
        "icon": "https://flagcdn.com/vn.svg",
        "name": "Vietnam",
        "code": "VN"
    },
    {
        "icon": "https://flagcdn.com/wf.svg",
        "name": "Wallis and Futuna",
        "code": "WF"
    },
    {
        "icon": "https://flagcdn.com/eh.svg",
        "name": "Western Sahara",
        "code": "EH"
    },
    {
        "icon": "https://flagcdn.com/ye.svg",
        "name": "Yemen",
        "code": "YE"
    },
    {
        "icon": "https://flagcdn.com/zm.svg",
        "name": "Zambia",
        "code": "ZM"
    },
    {
        "icon": "https://flagcdn.com/zw.svg",
        "name": "Zimbabwe",
        "code": "ZW"
    }
]
export const allCountriesListV3 = [
  {
      "name": "Afrikaans"
  },
  {
      "name": "Albanian"
  },
  {
      "name": "Amharic"
  },
  {
      "name": "Arabic"
  },
  {
      "name": "Aramaic"
  },
  {
      "name": "Armenian"
  },
  {
      "name": "Aymara"
  },
  {
      "name": "Azerbaijani"
  },
  {
      "name": "Basque"
  },
  {
      "name": "Belarusian"
  },
  {
      "name": "Belizean Creole"
  },
  {
      "name": "Bengali"
  },
  {
      "name": "Berber"
  },
  {
      "name": "Bislama"
  },
  {
      "name": "Bosnian"
  },
  {
      "name": "Bulgarian"
  },
  {
      "name": "Burmese"
  },
  {
      "name": "Carolinian"
  },
  {
      "name": "Catalan"
  },
  {
      "name": "Chamorro"
  },
  {
      "name": "Chewa"
  },
  {
      "name": "Chibarwe"
  },
  {
      "name": "Chinese"
  },
  {
      "name": "Comorian"
  },
  {
      "name": "Cook Islands Māori"
  },
  {
      "name": "Croatian"
  },
  {
      "name": "Czech"
  },
  {
      "name": "Danish"
  },
  {
      "name": "Dari"
  },
  {
      "name": "Dutch"
  },
  {
      "name": "Dzongkha"
  },
  {
      "name": "English"
  },
  {
      "name": "Estonian"
  },
  {
      "name": "Faroese"
  },
  {
      "name": "Fiji Hindi"
  },
  {
      "name": "Fijian"
  },
  {
      "name": "Filipino"
  },
  {
      "name": "Finnish"
  },
  {
      "name": "French"
  },
  {
      "name": "Galician"
  },
  {
      "name": "Georgian"
  },
  {
      "name": "German"
  },
  {
      "name": "Gilbertese"
  },
  {
      "name": "Greek"
  },
  {
      "name": "Greenlandic"
  },
  {
      "name": "Guaraní"
  },
  {
      "name": "Guernésiais"
  },
  {
      "name": "Haitian Creole"
  },
  {
      "name": "Hassaniya"
  },
  {
      "name": "Hebrew"
  },
  {
      "name": "Herero"
  },
  {
      "name": "Hindi"
  },
  {
      "name": "Hiri Motu"
  },
  {
      "name": "Hungarian"
  },
  {
      "name": "Icelandic"
  },
  {
      "name": "Indonesian"
  },
  {
      "name": "Irish"
  },
  {
      "name": "Italian"
  },
  {
      "name": "Jamaican Patois"
  },
  {
      "name": "Japanese"
  },
  {
      "name": "Jèrriais"
  },
  {
      "name": "Kalanga"
  },
  {
      "name": "Kazakh"
  },
  {
      "name": "Khmer"
  },
  {
      "name": "Khoekhoe"
  },
  {
      "name": "Khoisan"
  },
  {
      "name": "Kikongo"
  },
  {
      "name": "Kinyarwanda"
  },
  {
      "name": "Kirundi"
  },
  {
      "name": "Korean"
  },
  {
      "name": "Kwangali"
  },
  {
      "name": "Kyrgyz"
  },
  {
      "name": "Lao"
  },
  {
      "name": "Latin"
  },
  {
      "name": "Latvian"
  },
  {
      "name": "Lingala"
  },
  {
      "name": "Lithuanian"
  },
  {
      "name": "Lozi"
  },
  {
      "name": "Luxembourgish"
  },
  {
      "name": "Macedonian"
  },
  {
      "name": "Malagasy"
  },
  {
      "name": "Malay"
  },
  {
      "name": "Maldivian"
  },
  {
      "name": "Maltese"
  },
  {
      "name": "Manx"
  },
  {
      "name": "Māori"
  },
  {
      "name": "Marshallese"
  },
  {
      "name": "Mauritian Creole"
  },
  {
      "name": "Mongolian"
  },
  {
      "name": "Montenegrin"
  },
  {
      "name": "Nauru"
  },
  {
      "name": "Ndau"
  },
  {
      "name": "Ndonga"
  },
  {
      "name": "Nepali"
  },
  {
      "name": "New Zealand Sign Language"
  },
  {
      "name": "Niuean"
  },
  {
      "name": "Norfuk"
  },
  {
      "name": "Northern Ndebele"
  },
  {
      "name": "Northern Sotho"
  },
  {
      "name": "Norwegian"
  },
  {
      "name": "Norwegian Bokmål"
  },
  {
      "name": "Norwegian Nynorsk"
  },
  {
      "name": "Palauan"
  },
  {
      "name": "Papiamento"
  },
  {
      "name": "Pashto"
  },
  {
      "name": "Persian (Farsi)"
  },
  {
      "name": "Polish"
  },
  {
      "name": "Portuguese"
  },
  {
      "name": "Quechua"
  },
  {
      "name": "Romanian"
  },
  {
      "name": "Romansh"
  },
  {
      "name": "Russian"
  },
  {
      "name": "Sami"
  },
  {
      "name": "Samoan"
  },
  {
      "name": "Sango"
  },
  {
      "name": "Serbian"
  },
  {
      "name": "Seychellois Creole"
  },
  {
      "name": "Shona"
  },
  {
      "name": "Sinhala"
  },
  {
      "name": "Slovak"
  },
  {
      "name": "Slovene"
  },
  {
      "name": "Somali"
  },
  {
      "name": "Sorani"
  },
  {
      "name": "Sotho"
  },
  {
      "name": "Southern Ndebele"
  },
  {
      "name": "Southern Sotho"
  },
  {
      "name": "Spanish"
  },
  {
      "name": "Swahili"
  },
  {
      "name": "Swazi"
  },
  {
      "name": "Swedish"
  },
  {
      "name": "Swiss German"
  },
  {
      "name": "Tajik"
  },
  {
      "name": "Tamil"
  },
  {
      "name": "Tetum"
  },
  {
      "name": "Thai"
  },
  {
      "name": "Tigrinya"
  },
  {
      "name": "Tok Pisin"
  },
  {
      "name": "Tokelauan"
  },
  {
      "name": "Tonga"
  },
  {
      "name": "Tongan"
  },
  {
      "name": "Tshiluba"
  },
  {
      "name": "Tsonga"
  },
  {
      "name": "Tswana"
  },
  {
      "name": "Turkish"
  },
  {
      "name": "Turkmen"
  },
  {
      "name": "Tuvaluan"
  },
  {
      "name": "Ukrainian"
  },
  {
      "name": "Upper Guinea Creole"
  },
  {
      "name": "Urdu"
  },
  {
      "name": "Uzbek"
  },
  {
      "name": "Venda"
  },
  {
      "name": "Vietnamese"
  },
  {
      "name": "Xhosa"
  },
  {
      "name": "Zimbabwean Sign Language"
  },
  {
      "name": "Zulu"
  }
]
